<template>
  <v-container fluid>
    <base-material-card color="primary" class="px-5 py-3">
      <template v-slot:heading>
        <v-row class="ma-auto">
          <v-col md="6" sm="8">
            {{$t('tables.paid-orders-history')}}
            <v-badge offset-x="-5" offset-y="-5" color="blue"
                     :content="paymentHistory.length?paymentHistory.length:'0'"></v-badge>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-card-title>
        <v-row class="ma-auto">
          <v-col cols="12" md="4" sm="12">
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-data-table
        :loading-text="$t('loadings.loading-payment-history')"
        :no-data-text="$t('loadings.no-payment-history-found')"
        single-expand
        item-key="orderId"
        :loading="loading"
        multi-sort
        :headers="headers"
        :items="paymentHistory"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]
              }">
        <template v-slot:item.agency.agencyName="{ item }">
          <router-link :to="{ path: '/agency/' + item.agency.agencyId}">
            {{item.agency.agencyName}}
          </router-link>
        </template>
        <template v-slot:item.orderCode="{ item }">
          <router-link :to="{ path: '/order/' + item.orderId}" style="text-decoration: none">
            {{item.orderCode}}
          </router-link>
        </template>
        <template v-slot:item.payer.payerName="{ item }">
          <router-link v-if="item.payer" :to="{ path: '/payer/' + item.payer.payerId}" style="text-decoration: none">
            {{item.payer.payerName}}
          </router-link>
        </template>
        <template v-slot:item.createdTime="{ item }">
          <span :key="parseTime(item.createdTime)">{{parseTime(item.createdTime)}}</span>
        </template>
        <template v-slot:item.paymentDate="{ item }">
          <span :key="parseTime(item.paymentDate)">{{parseTime(item.paymentDate)}}</span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length/2">
            <v-simple-table dense>
              <tr>
                <td> {{$t('table-expand.created-date')}}:</td>
                <td>
                  <h5>{{ parseTime(item.createdTime) }}</h5>
                </td>
                <td> {{$t('table-expand.modified-date')}}:</td>
                <td>
                  <h5>{{ parseTime(item.modifiedTime) }}</h5>
                </td>
              </tr>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";
  import {mapState} from "pinia";
  import {useOrders} from "../../stores/ordersStore";

  const ordersStore = useOrders()

  let moment = require('moment');

  export default {
    name: "Payment History",
    components: {CreateObjectButton},
    data() {
      return {
        paymentHistory: [],
        expanded: [],
        search: '',
        headers: [
          {
            text: this.$t('tables.order-code'),
            align: 'start',
            sortable: true,
            value: 'orderCode',
            class: "primary--text",
          },
          {text: this.$t('tables.paymentDate'), value: 'paymentDate', class: "primary--text"},
          {text: this.$t('tables.agency'), value: 'agency.agencyName', class: "primary--text"},
          {text: this.$t('tables.payerName'), value: 'payer.payerName', class: "primary--text"},
          {
            text: this.$t('tables.incomingNumber'),
            value: 'incomingNumber',
            class: "primary--text",
            sortable: true,
            align: ' d-none'
          },
        ],
      }
    },
    methods: {
      async updateNeededCheck() {
        if (this.orders.length) {
          this.paymentHistory = this.orders
            .filter(e => e.paid === true)
            .sort((a, b) => (a.paymentDate > b.paymentDate) ? -1 : 1);
        }
        if (!this.orders.length) {
          let response = await ordersStore.getOrdersAsync()
          this.paymentHistory = response.data
            .filter(e => e.paid === true)
            .sort((a, b) => (a.paymentDate > b.paymentDate) ? -1 : 1);
        }
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: ""})
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
    },
    computed: {
      ...mapState(useOrders, ['orders', 'loading']),
    },
    created() {
      this.updateNeededCheck();
    }
  }
</script>
